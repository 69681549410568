import React, { ChangeEvent, memo, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import InputTextField, { InputStatus } from 'components/InputTextField/InputTextField';
import RadioField from 'components/RadioField/RadioField';
import PhoneInputWrapper from 'components/PhoneInputWrapper/PhoneInputWrapper';
import DatePicker from 'components/DatePicker/DatePicker';
import SignaturePadField from 'components/SignaturePadField/SignaturePadField';
import MeasurementInputGroup from 'components/MeasurementInputGroup/MeasurementInputGroup';
import FormSection from 'components/FormSection/FormSection';
import parseCustomerDob from 'utils/parseCustomerDob';
import formatUSPhoneNumber from 'utils/formatUSPhoneNumber';
import isValidUSPhoneNumber from 'utils/isValidUSPhoneNumber';
import i18nNamespaces from 'i18n/i18nNamespaces';
import { FormComponentProps } from '../../../types/FormComponentProps';
import FormDetailsLayout from '../../FormDetailsLayout/FormDetailsLayout';
import './AssessmentAndCarePlanForFootOrthoticsShoeOnly.scss';
import getFormGenderOptions from '../../../utils/getFormGenderOptions';
import getFormYesNoOptions from '../../../utils/getFormYesNoOptions';
import useMeasurementOptions from '../../../hooks/useMeasurementOptions';
import { skipUnitFields, withUnit } from '../../../utils/unitUtils';
import getMultipleChoiceClickHandler from '../../../utils/getMultipleChoiceClickHandler';
import getFormRelationshipOptions from '../../../utils/getFormRelationshipOptions';

const splitCommaString = (value: unknown) => (typeof value === 'string' ? value.split(',') : value);

interface Props<T = any> extends FormComponentProps<T> {
  namePlaceholder?: string;
  datePlaceholder?: string;
}

export const AssessmentAndCarePlanForFootOrthoticsShoeOnly = memo<Props>(
  ({
    form,
    onSubmit,
    loading,
    submitError,
    ctaLabel,
    ctaLoadingLabel,
    secondaryCtaLabel,
    tForm,
    mode = 'all',
    namePlaceholder,
    datePlaceholder,
  }) => {
    const { t: tLocalForm } = useTranslation(i18nNamespaces.FORM);

    const prefillEnabled = mode === 'prefill' || mode === 'all';
    const patientEnabled = mode === 'patient' || mode === 'all';

    const { getMeasurementOptions: getSmallLengthsUnitsOptions, tMeasurement } = useMeasurementOptions('length', [
      'cm',
      'in',
    ]);
    const { getMeasurementOptions: getBodyWeightUnitsOptions } = useMeasurementOptions('massWeight', ['kg', 'lb']);
    const genderRadioOptions = useMemo(() => getFormGenderOptions(tForm), [tForm]);
    const relationshipRadioOptions = useMemo(() => getFormRelationshipOptions(tForm), [tForm]);
    const yesNoOptions = useMemo(() => getFormYesNoOptions(tForm), [tForm]);

    const orderData = form?.paOrderData;
    const adminData = form?.adminData?.data;

    const {
      register,
      handleSubmit,
      formState: { errors },
      getValues,
      setValue,
      watch,
    } = useForm({
      defaultValues: {
        ...adminData,
        name: form?.name ?? '',
        dob: orderData?.dob ? parseCustomerDob(orderData?.dob) : '',
        date: format(new Date(), 'yyyy-MM-dd'),
        gender: orderData?.gender,
        address: orderData?.address,
        phone: orderData?.phone,
        weight: adminData?.weight?.split(' ')?.shift() ?? orderData?.weight,
        weightUnit: adminData?.weight?.split(' ')?.pop(),
        height: adminData?.height?.split(' ')?.shift() ?? orderData?.height,
        heightUnit: adminData?.height?.split(' ')?.pop(),
        primaryInsurance: adminData?.primaryInsurance ?? orderData?.primaryInsurance,
        secondaryInsurance: adminData?.secondaryInsurance ?? orderData?.secondaryInsurance,
        dischargedDate: adminData?.dischargedDate ?? format(new Date(), 'yyyy-MM-dd'),
        itemPrescribed: {
          ...adminData?.itemPrescribed,
          previousProductDate: adminData?.itemPrescribed?.previousProductDate ?? format(new Date(), 'yyyy-MM-dd'),
        },
        pa: {
          ...adminData?.pa,
          approvedDate: adminData?.pa?.approvedDate ?? format(new Date(), 'yyyy-MM-dd'),
          dateOfServiceFrom: adminData?.pa?.dateOfServiceFrom ?? format(new Date(), 'yyyy-MM-dd'),
          dateOfServiceTo: adminData?.pa?.dateOfServiceTo ?? format(new Date(), 'yyyy-MM-dd'),
        },
        signingName: form?.name ?? '',
        signingDate: format(new Date(), 'yyyy-MM-dd'),
      } as any,
    });
    const { signature: signatureValue } = getValues();

    const validatePhone = (phone: string) => {
      if (!isValidUSPhoneNumber(phone)) return 'Phone Invalid';
      return true;
    };

    const onSubmitWithDataMapping = useCallback(
      (data = {}) => {
        const {
          wasDischargedFromHospital,
          dischargedDate,
          itemPrescribed = {},
          assessment = {},
          carePlan = {},
          functionalGoals,
        } = data;
        const {
          shoeSpecification,
          similarTypeMadeBefore,
          previousType,
          previousProduct,
          previousProductDate,
          wantSameAsBefore,
          ...restItemPrescribed
        } = itemPrescribed;
        const { language, symptoms } = assessment;
        const { dispensedItems, orderedItems } = carePlan;

        const mappedData = {
          ...skipUnitFields(data),
          weight: withUnit('weight', data),
          height: withUnit('height', data),
          dischargedDate: wasDischargedFromHospital === 'no' ? undefined : dischargedDate,
          itemPrescribed: {
            ...restItemPrescribed,
            shoeSpecification: splitCommaString(shoeSpecification),
            similarTypeMadeBefore,
            ...(similarTypeMadeBefore === 'yes'
              ? {
                  previousType,
                  previousProduct,
                  previousProductDate,
                  wantSameAsBefore,
                }
              : {}),
          },
          assessment: {
            ...assessment,
            language: splitCommaString(language),
            symptoms: splitCommaString(symptoms),
          },
          carePlan: {
            ...carePlan,
            dispensedItems: splitCommaString(dispensedItems),
            orderedItems: splitCommaString(orderedItems),
          },
          functionalGoals: splitCommaString(functionalGoals),
        };
        return onSubmit(mappedData);
      },
      [onSubmit],
    );

    const renderYesNo = (
      fieldName: string,
      labelTKey = fieldName,
      required = true,
      defaultStatus: InputStatus = 'active',
    ) => (
      <RadioField
        {...register(fieldName, { required })}
        label={tForm(labelTKey)}
        options={yesNoOptions}
        disabled={loading || defaultStatus === 'disabled'}
        status={(errors || {})[fieldName] ? 'error' : defaultStatus}
        inline
      />
    );

    const renderBasicInfoSection = (fieldsDisabled?: boolean) => {
      const defaultStatus = fieldsDisabled ? 'disabled' : 'active';
      const wasDischargedFromHospital = watch('wasDischargedFromHospital') === 'yes';
      return (
        <FormSection>
          <InputTextField
            {...register('name')}
            label={tForm('patientName')}
            placeholder={namePlaceholder}
            type="text"
            status="disabled"
          />
          <InputTextField {...register('dob')} label={tForm('dob')} type="text" status="disabled" />
          <RadioField
            {...register('gender')}
            label={tForm('gender')}
            options={genderRadioOptions}
            disabled
            status={errors?.gender ? 'error' : defaultStatus}
          />
          <InputTextField {...register('address')} label={tForm('address')} type="text" status="disabled" />
          <PhoneInputWrapper hasError={false} hasErrorMessage={false}>
            {({ className, inputClassName, elementAfter }) => (
              <InputTextField
                {...register('phone')}
                label={tForm('phone')}
                type="text"
                className={className}
                inputClassName={inputClassName}
                elementAfter={elementAfter}
                status="disabled"
              />
            )}
          </PhoneInputWrapper>
          <InputTextField
            {...register('date')}
            label={tForm('date')}
            placeholder={datePlaceholder}
            type="date"
            status="disabled"
          />
          <InputTextField
            {...register('doneBy')}
            label={tForm('doneBy')}
            type="text"
            maxLength={50}
            status={errors?.doneBy ? 'error' : defaultStatus}
          />
          {renderYesNo('isNewPatient', 'newPatient', !fieldsDisabled, defaultStatus)}
          {renderYesNo('isCashPurchase', 'cashPurchase', false, defaultStatus)}
          <MeasurementInputGroup
            name="weight"
            unitName="weightUnit"
            numberValue={watch('weight')}
            unitValue={watch('weightUnit')}
            setValue={setValue}
            register={register}
            getMeasurementOptions={getBodyWeightUnitsOptions}
            label={tForm('ptWeight')}
            placeholder={`${tLocalForm('eg')} ${60}`}
            unitPlaceholder={`${tLocalForm('eg')} ${tMeasurement('kilogram_plural')}`}
            errors={errors}
            defaultStatus={defaultStatus}
            required
            autoSelectedUnit="kg"
          />
          <MeasurementInputGroup
            name="height"
            unitName="heightUnit"
            numberValue={watch('height')}
            unitValue={watch('heightUnit')}
            setValue={setValue}
            register={register}
            getMeasurementOptions={getSmallLengthsUnitsOptions}
            label={tForm('ptHeight')}
            placeholder={`${tLocalForm('eg')} ${170}`}
            unitPlaceholder={`${tLocalForm('eg')} ${tMeasurement('centimeter_plural')}`}
            errors={errors}
            defaultStatus={defaultStatus}
            required
            autoSelectedUnit="cm"
          />
          <InputTextField
            {...register('prescriber')}
            label={tForm('prescriber')}
            type="text"
            status={errors?.prescriber ? 'error' : defaultStatus}
          />
          <InputTextField
            {...register('primaryInsurance')}
            label={tForm('primaryIns')}
            type="text"
            status={errors?.primaryInsurance ? 'error' : defaultStatus}
          />
          <InputTextField
            {...register('secondaryInsurance')}
            label={tForm('secondaryIns')}
            type="text"
            status={errors?.secondaryInsurance ? 'error' : defaultStatus}
          />
          <InputTextField
            {...register('emergencyContactName')}
            label={tForm('emergencyContactName')}
            type="text"
            maxLength={50}
            status={errors?.emergencyContactName ? 'error' : defaultStatus}
          />
          <PhoneInputWrapper hasError={!!errors?.emergencyPhone} hasErrorMessage={!!errors?.emergencyPhone?.message}>
            {({ className, inputClassName, elementAfter }) => (
              <InputTextField
                {...register('emergencyPhone', { validate: validatePhone })}
                label={tForm('emergencyPhone')}
                placeholder="(201) 555-5555"
                type="text"
                className={className}
                inputClassName={inputClassName}
                elementAfter={elementAfter}
                status={errors?.emergencyPhone ? 'error' : defaultStatus}
                bottomLabel={errors?.emergencyPhone?.message}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setValue('emergencyPhone', formatUSPhoneNumber(e.target.value));
                }}
                onBlur={(e) => setValue('emergencyPhone', formatUSPhoneNumber(e.target.value))}
              />
            )}
          </PhoneInputWrapper>
          <InputTextField
            {...register('emergencyRelationship')}
            label={tForm('relation')}
            type="text"
            maxLength={30}
            status={errors?.emergencyRelationship ? 'error' : defaultStatus}
          />
          {renderYesNo('haveVisitingNurse', 'haveAVisitingNurse', !fieldsDisabled, defaultStatus)}
          <RadioField
            {...register('wasDischargedFromHospital', { required: !fieldsDisabled })}
            label={tForm('wasPatientRecentlyDischargedFromHospital')}
            options={[
              {
                ...yesNoOptions[0],
                nodeAfter: (
                  <DatePicker
                    {...register('dischargedDate', { required: wasDischargedFromHospital && !fieldsDisabled })}
                    forceHideLabel
                    placeholder="Select date..."
                    maxDate={new Date()}
                    date={new Date(watch('dischargedDate'))}
                    onChange={(date: Date) => setValue('dischargedDate', format(date, 'yyyy-MM-dd'))}
                    status={wasDischargedFromHospital ? 'active' : defaultStatus}
                    disabled={!wasDischargedFromHospital || defaultStatus === 'disabled'}
                    className="AssessmentAndCarePlanForFootOrthoticsShoeOnly-input-date-picker"
                    calendarAlign="left"
                  />
                ),
                className: 'AssessmentAndCarePlanForFootOrthoticsShoeOnly-radio-with-input',
              },
              yesNoOptions[1],
            ]}
            disabled={loading}
            status={errors?.wasDischargedFromHospital ? 'error' : defaultStatus}
          />
        </FormSection>
      );
    };

    const renderItemPrescribedSection = (fieldsDisabled?: boolean) => {
      const ns = 'itemPrescribed';
      const defaultStatus = fieldsDisabled ? 'disabled' : 'active';
      const withNs = (name: string) => `${ns}.${name}`;
      const err = (name: string) => ((errors || {})[ns] || {})[name];

      const shoeSpecificationValue = watch(withNs('shoeSpecification'));
      const hasSimilarTypeMadeBefore = watch(withNs('similarTypeMadeBefore')) === 'yes';
      const NotUnderstandWhatDoctorOrdered = watch(withNs('understandWhatDoctorOrdered')) === 'no';
      const isPrevTypeOther = watch(withNs('previousType')) === 'other';
      const previousTypeFieldsStatus = hasSimilarTypeMadeBefore ? defaultStatus : 'disabled';

      return (
        <FormSection title={tForm('itemPrescribed_plural')}>
          <RadioField
            {...register(withNs('orthopedicShoes'), { required: !fieldsDisabled })}
            label={tForm('orthopedicShoes')}
            options={[
              { value: 'offTheShelf', label: tForm('offTheShelf') },
              { value: 'custom', label: tForm('custom') },
            ]}
            disabled={loading}
            status={err('orthopedicShoes') ? 'error' : defaultStatus}
          />
          <RadioField
            {...register(withNs('insoles'), { required: !fieldsDisabled })}
            label={tForm('insoles')}
            options={[
              { value: 'heatFormed', label: tForm('heatFormed') },
              { value: 'customMolded', label: tForm('customMolded') },
            ]}
            disabled={loading}
            status={err('insoles') ? 'error' : defaultStatus}
          />
          <RadioField
            {...register(withNs('shoeSpecification'), { required: !fieldsDisabled })}
            label={tForm('specifications')}
            options={[
              { value: 'metPads', label: tForm('metPads') },
              { value: 'heelCutOut', label: tForm('heelCutOut') },
              { value: 'toeCrest', label: tForm('toeCrest') },
              { value: 'cuboidPads', label: tForm('cuboidPads') },
            ]}
            disabled={loading}
            status={err('shoeSpecification') ? 'error' : defaultStatus}
            multipleChoice
            type="checkbox"
            value={shoeSpecificationValue}
            onClick={getMultipleChoiceClickHandler(withNs('shoeSpecification'), shoeSpecificationValue, setValue)}
          />
          <InputTextField
            {...register(withNs('shoeSpecificationNote'))}
            label={tForm('note')}
            type="text"
            maxLength={50}
            status={err('shoeSpecificationNote') ? 'error' : defaultStatus}
          />
          <InputTextField
            {...register(withNs('other'))}
            label={tForm('other')}
            type="text"
            maxLength={50}
            status={err('other') ? 'error' : defaultStatus}
          />
          <InputTextField
            {...register(withNs('diagnosesRx'))}
            label={tForm('diagnosesOnRx')}
            type="text"
            maxLength={50}
            status={err('diagnosesRx') ? 'error' : defaultStatus}
          />
          {renderYesNo(withNs('diabetes'), 'diabetes', !fieldsDisabled, defaultStatus)}
          <RadioField
            {...register(withNs('understandWhatDoctorOrdered'), { required: !fieldsDisabled })}
            label={tForm('doesPatientUnderstandWhatTheDoctorHasOrdered')}
            options={[
              yesNoOptions[0],
              {
                ...yesNoOptions[1],
                nodeAfter: (
                  <InputTextField
                    {...register(withNs('whatDoctorOrdered'))}
                    forceHideLabel
                    type="text"
                    maxLength={80}
                    status={
                      err('whatDoctorOrdered') ? 'error' : NotUnderstandWhatDoctorOrdered ? defaultStatus : 'disabled'
                    }
                    className="AssessmentAndCarePlanForFootOrthoticsShoeOnly-input-after-input"
                  />
                ),
                className: 'AssessmentAndCarePlanForFootOrthoticsShoeOnly-radio-with-input',
              },
            ]}
            disabled={loading}
            status={err('understandWhatDoctorOrdered') ? 'error' : defaultStatus}
          />
          {renderYesNo(
            withNs('similarTypeMadeBefore'),
            'hasPatientHadSimilarTypeOfProductMadeHereBefore',
            !fieldsDisabled,
            defaultStatus,
          )}
          <RadioField
            {...register(withNs('previousType'), { required: hasSimilarTypeMadeBefore && !fieldsDisabled })}
            label={tForm('ifYes')}
            options={[
              { value: 'shoes', label: tForm('shoes') },
              { value: 'customInsoles', label: tForm('customInsoles') },
              {
                value: 'other',
                label: tForm('other'),
                nodeAfter: (
                  <InputTextField
                    {...register(withNs('previousProduct'))}
                    forceHideLabel
                    type="text"
                    maxLength={80}
                    status={err('previousProduct') ? 'error' : isPrevTypeOther ? defaultStatus : 'disabled'}
                    className="AssessmentAndCarePlanForFootOrthoticsShoeOnly-input-after-input"
                  />
                ),
                className: 'AssessmentAndCarePlanForFootOrthoticsShoeOnly-radio-with-input',
              },
            ]}
            disabled={loading || previousTypeFieldsStatus === 'disabled'}
            status={err('previousType') ? 'error' : previousTypeFieldsStatus}
          />
          <DatePicker
            {...register(withNs('previousProductDate'), { required: hasSimilarTypeMadeBefore && !fieldsDisabled })}
            label={tForm('when')}
            placeholder="Select date..."
            maxDate={new Date()}
            date={new Date(watch(withNs('previousProductDate')))}
            onChange={(date: Date) => setValue(withNs('previousProductDate'), format(date, 'yyyy-MM-dd'))}
            status={err('previousProductDate') ? 'error' : previousTypeFieldsStatus}
            disabled={previousTypeFieldsStatus === 'disabled'}
            className="AssessmentAndCarePlanForFootOrthoticsShoeOnly-input-date-picker"
            calendarAlign="left"
          />
          {renderYesNo(
            withNs('wantSameAsBefore'),
            'ifTheOrderIsSameAsBeforeDoYouWantSameMaterialsStyle',
            hasSimilarTypeMadeBefore && !fieldsDisabled,
            previousTypeFieldsStatus,
          )}
          <InputTextField
            {...register(withNs('wantSameAsBeforeNote'))}
            label={tForm('note')}
            type="text"
            maxLength={50}
            status={err('wantSameAsBeforeNote') ? 'error' : previousTypeFieldsStatus}
          />
        </FormSection>
      );
    };

    const renderAssessmentSection = (fieldsDisabled?: boolean) => {
      const ns = 'assessment';
      const defaultStatus = fieldsDisabled ? 'disabled' : 'active';
      const withNs = (name: string) => `${ns}.${name}`;
      const err = (name: string) => ((errors || {})[ns] || {})[name];

      const languageValue = watch(withNs('language'));
      const symptomsValue = watch(withNs('symptoms'));
      const otherLanguageDefaultStatus =
        typeof languageValue === 'string' && languageValue?.split(',')?.includes('other') ? defaultStatus : 'disabled';
      const otherSymptomsDefaultStatus =
        typeof symptomsValue === 'string' && symptomsValue?.split(',')?.includes('other') ? defaultStatus : 'disabled';

      return (
        <FormSection title={tForm('assessment')}>
          <RadioField
            {...register(withNs('language'), { required: !fieldsDisabled })}
            label={tForm('language')}
            options={[
              { value: 'en', label: tForm('english') },
              { value: 'es', label: tForm('Spanish') },
              { value: 'cantonese', label: tForm('Cantonese') },
              { value: 'mandarin', label: tForm('Mandarin') },
              { value: 'fukienese', label: tForm('Fukienese') },
              {
                value: 'other',
                label: tForm('other'),
                nodeAfter: (
                  <InputTextField
                    {...register(withNs('otherLanguage'))}
                    forceHideLabel
                    type="text"
                    maxLength={50}
                    status={err('otherLanguage') ? 'error' : otherLanguageDefaultStatus}
                    className="AssessmentAndCarePlanForFootOrthoticsShoeOnly-input-after-input"
                  />
                ),
                className: 'AssessmentAndCarePlanForFootOrthoticsShoeOnly-radio-with-input',
              },
            ]}
            disabled={loading}
            status={err('language') ? 'error' : defaultStatus}
            multipleChoice
            type="checkbox"
            value={languageValue}
            onClick={getMultipleChoiceClickHandler(withNs('language'), languageValue, setValue)}
          />
          <RadioField
            {...register(withNs('understandInstruction'), { required: !fieldsDisabled })}
            label={tForm('understandInstructions')}
            options={[
              { value: 'yes', label: tForm('yes') },
              { value: 'assisted', label: tForm('assistedByFamilyMemberHomeAttendant') },
            ]}
            disabled={loading}
            status={err('understandInstruction') ? 'error' : defaultStatus}
          />
          <InputTextField
            {...register(withNs('understandInstructionNote'))}
            label={tForm('note')}
            type="text"
            maxLength={50}
            status={err('understandInstructionNote') ? 'error' : defaultStatus}
          />
          <RadioField
            {...register(withNs('symptoms'), { required: !fieldsDisabled })}
            label={tForm('symptom_plural')}
            options={[
              { value: 'footPain', label: tForm('footPain') },
              { value: 'flatFoot', label: tForm('flatFoot') },
              { value: 'heelPain', label: tForm('heelPain') },
              { value: 'hammerToes', label: tForm('hammerToes') },
              { value: 'overlappingTose', label: tForm('overlappingTose') },
              { value: 'unequalLegLength', label: tForm('unequalLegLength') },
              {
                value: 'other',
                label: tForm('otherPleaseSpecify'),
                nodeAfter: (
                  <InputTextField
                    {...register(withNs('otherSymptoms'))}
                    forceHideLabel
                    type="text"
                    maxLength={200}
                    status={err('otherSymptoms') ? 'error' : otherSymptomsDefaultStatus}
                    className="AssessmentAndCarePlanForFootOrthoticsShoeOnly-input-after-input"
                  />
                ),
                className: 'AssessmentAndCarePlanForFootOrthoticsShoeOnly-radio-with-input',
              },
            ]}
            disabled={loading}
            status={err('symptoms') ? 'error' : defaultStatus}
            multipleChoice
            type="checkbox"
            value={symptomsValue}
            onClick={getMultipleChoiceClickHandler(withNs('symptoms'), symptomsValue, setValue)}
          />
          <InputTextField
            {...register(withNs('relatedHistory'))}
            label={tForm('relatedHistoryOfInjuryOperations')}
            type="text"
            maxLength={100}
            status={err('relatedHistory') ? 'error' : defaultStatus}
          />
          <RadioField
            {...register(withNs('activityLevel'), { required: !fieldsDisabled })}
            label={tForm('activityLevel')}
            options={[
              { value: 'withoutAids', label: tForm('walkWithNoAssistance') },
              { value: 'withAids', label: tForm('walkWithCaneOrAmbulatoryAids') },
            ]}
            disabled={loading}
            status={err('activityLevel') ? 'error' : defaultStatus}
          />
          {renderYesNo(withNs('patientWork'), 'doesPatientWork', !fieldsDisabled, defaultStatus)}
          {renderYesNo(withNs('problemAffectsWork'), 'doesYourProblemAffectYourWork', !fieldsDisabled, defaultStatus)}
        </FormSection>
      );
    };

    const renderCarePlanSection = (fieldsDisabled?: boolean) => {
      const ns = 'carePlan';
      const defaultStatus = fieldsDisabled ? 'disabled' : 'active';
      const withNs = (name: string) => `${ns}.${name}`;
      const err = (name: string) => ((errors || {})[ns] || {})[name];

      const selectedPlanValue = watch(withNs('selectedPlan'));
      const isProceedToOrder = selectedPlanValue === 'proceedToOrder';
      const isFurtherConfirmationNeeded = selectedPlanValue === 'needFurtherConfirmation';

      const renderProceedToOrderFields = (required: boolean) => {
        const dispensedItemsValue = watch(withNs('dispensedItems'));
        const hasOtherDispensedItem =
          typeof dispensedItemsValue === 'string' && dispensedItemsValue?.split(',').includes('other');
        const dispensedItemsOtherStatus = hasOtherDispensedItem ? defaultStatus : 'disabled';

        const orderedItemsValue = watch(withNs('orderedItems'));
        const hasOtherOrderedItems =
          typeof orderedItemsValue === 'string' && orderedItemsValue?.split(',').includes('other');
        const orderedItemsOtherStatus = hasOtherOrderedItems ? defaultStatus : 'disabled';
        return (
          <>
            <RadioField
              {...register(withNs('dispensedItems'), { required })}
              label={tForm('itemsToBeDispensedImmediately')}
              options={[
                { value: 'withoutAids', label: tForm('walkWithNoAssistance') },
                { value: 'withAids', label: tForm('walkWithCaneOrAmbulatoryAids') },
                {
                  value: 'other',
                  label: tForm('other'),
                  nodeAfter: (
                    <InputTextField
                      {...register(withNs('dispensedItemsOther'), {
                        required: hasOtherDispensedItem && !fieldsDisabled,
                      })}
                      forceHideLabel
                      type="text"
                      maxLength={200}
                      status={err('dispensedItemsOther') ? 'error' : dispensedItemsOtherStatus}
                      className="AssessmentAndCarePlanForFootOrthoticsShoeOnly-input-after-input"
                    />
                  ),
                  className: 'AssessmentAndCarePlanForFootOrthoticsShoeOnly-radio-with-input',
                },
              ]}
              disabled={loading}
              status={err('dispensedItems') ? 'error' : defaultStatus}
              multipleChoice
              type="checkbox"
              value={dispensedItemsValue}
              onClick={getMultipleChoiceClickHandler(withNs('dispensedItems'), dispensedItemsValue, setValue)}
            />
            <RadioField
              {...register(withNs('orderedItems'), { required })}
              label={tForm('itemsToBeFabricatedOrdered')}
              options={[
                { value: 'withoutAids', label: tForm('walkWithNoAssistance') },
                { value: 'withAids', label: tForm('walkWithCaneOrAmbulatoryAids') },
                {
                  value: 'other',
                  label: tForm('other'),
                  nodeAfter: (
                    <InputTextField
                      {...register(withNs('orderedItemsOther'), { required: hasOtherOrderedItems && !fieldsDisabled })}
                      forceHideLabel
                      type="text"
                      maxLength={200}
                      status={err('orderedItemsOther') ? 'error' : orderedItemsOtherStatus}
                      className="AssessmentAndCarePlanForFootOrthoticsShoeOnly-input-after-input"
                    />
                  ),
                  className: 'AssessmentAndCarePlanForFootOrthoticsShoeOnly-radio-with-input',
                },
              ]}
              disabled={loading}
              status={err('orderedItems') ? 'error' : defaultStatus}
              multipleChoice
              type="checkbox"
              value={orderedItemsValue}
              onClick={getMultipleChoiceClickHandler(withNs('orderedItems'), orderedItemsValue, setValue)}
            />
            <InputTextField
              {...register(withNs('fabricationDuration'), { required })}
              label={tForm('howLongItWillTakeForFabrication')}
              type="text"
              maxLength={100}
              status={err('fabricationDuration') ? 'error' : defaultStatus}
            />
          </>
        );
      };

      const renderFurtherConfirmationFields = (required: boolean) => (
        <InputTextField
          {...register(withNs('needFurtherConfirmation'), { required })}
          forceHideLabel
          type="text"
          maxLength={100}
          status={err('needFurtherConfirmation') ? 'error' : defaultStatus}
        />
      );

      return (
        <FormSection title={tForm('carePlan')}>
          <RadioField
            {...register(withNs('selectedPlan'), { required: !fieldsDisabled })}
            forceHideLabel
            options={[
              {
                value: 'proceedToOrder',
                label: tForm('proceedAccordingToPrescriberSOrder'),
              },
              {
                value: 'needFurtherConfirmation',
                label: tForm('needFurtherConfirmation'),
              },
            ]}
            disabled={loading}
            status={err('selectedPlan') ? 'error' : defaultStatus}
          />
          {isProceedToOrder && renderProceedToOrderFields(isProceedToOrder && !fieldsDisabled)}
          {isFurtherConfirmationNeeded &&
            renderFurtherConfirmationFields(isFurtherConfirmationNeeded && !fieldsDisabled)}
        </FormSection>
      );
    };

    const renderFunctionGoalsSection = (fieldsDisabled?: boolean) => {
      const defaultStatus = fieldsDisabled ? 'disabled' : 'active';

      const functionalGoalsValue = watch('functionalGoals');
      const hasOtherFunctionalGoals =
        typeof functionalGoalsValue === 'string' && functionalGoalsValue.split(',').includes('other');
      const otherFunctionalGoalsStatus = hasOtherFunctionalGoals ? defaultStatus : 'disabled';
      return (
        <FormSection title={tForm('functionalGoals')}>
          <RadioField
            {...register('functionalGoals', { required: !fieldsDisabled })}
            forceHideLabel
            options={[
              { value: 'preventOrCorrectDeformity', label: tForm('preventOrCorrectDeformity') },
              { value: 'reducePain', label: tForm('reducePain') },
              { value: 'improveGait', label: tForm('improveGait') },
              { value: 'mitigateSymptomsOrConditions', label: tForm('mitigateSymptomsOrConditions') },
              {
                value: 'other',
                label: tForm('other'),
                nodeAfter: (
                  <InputTextField
                    {...register('functionalGoalsOther', { required: hasOtherFunctionalGoals && !fieldsDisabled })}
                    forceHideLabel
                    type="text"
                    maxLength={50}
                    status={errors?.functionalGoalsOther ? 'error' : otherFunctionalGoalsStatus}
                    className="AssessmentAndCarePlanForFootOrthoticsShoeOnly-input-after-input"
                  />
                ),
                className: 'AssessmentAndCarePlanForFootOrthoticsShoeOnly-radio-with-input',
              },
            ]}
            disabled={loading}
            status={errors?.functionalGoals ? 'error' : defaultStatus}
            multipleChoice
            type="checkbox"
            value={functionalGoalsValue}
            onClick={getMultipleChoiceClickHandler('functionalGoals', functionalGoalsValue, setValue)}
          />
        </FormSection>
      );
    };

    const renderPASection = (fieldsDisabled?: boolean) => {
      const ns = 'pa';
      const defaultStatus = fieldsDisabled ? 'disabled' : 'active';
      const withNs = (name: string) => `${ns}.${name}`;
      const err = (name: string) => ((errors || {})[ns] || {})[name];

      const renderDatePicker = (name: string, labelTKey = name, minDate?: Date, maxDate?: Date) => (
        <DatePicker
          {...register(withNs(name), { required: !fieldsDisabled })}
          label={tForm(labelTKey)}
          placeholder="Select date..."
          minDate={minDate}
          maxDate={maxDate}
          date={new Date(watch(withNs(name)))}
          onChange={(date: Date) => setValue(withNs(name), format(date, 'yyyy-MM-dd'))}
          status={defaultStatus}
          disabled={defaultStatus === 'disabled'}
          className="AssessmentAndCarePlanForFootOrthoticsShoeOnly-input-date-picker"
          calendarAlign="left"
        />
      );

      return (
        <FormSection>
          <InputTextField
            {...register(withNs('name'), { required: !fieldsDisabled })}
            label={`${tForm('pA')} #`}
            type="text"
            maxLength={50}
            status={err('name') ? 'error' : defaultStatus}
          />
          <InputTextField
            {...register(withNs('confirmedBy'), { required: !fieldsDisabled })}
            label={tForm('confirmedBy')}
            type="text"
            maxLength={50}
            status={err('confirmedBy') ? 'error' : defaultStatus}
          />
          {renderDatePicker('approvedDate', 'approvedDate', undefined, new Date())}
          {renderDatePicker('dateOfServiceFrom', 'dateOfServiceFrom')}
          {renderDatePicker('dateOfServiceTo', 'to', new Date(watch(withNs('dateOfServiceFrom'))))}
        </FormSection>
      );
    };

    const renderFinancialResponsibilitiesSection = (fieldsDisabled?: boolean) => {
      const defaultStatus = fieldsDisabled ? 'disabled' : 'active';

      const renderAmountField = (name: string, labelTKey = name) => (
        <InputTextField
          {...register(name, { required: !fieldsDisabled })}
          label={tForm(labelTKey)}
          type="number"
          min={0}
          max={9999999999}
          step="0.01"
          status={(errors || {})[name] ? 'error' : defaultStatus}
          className="FormDetailsLayout-form-field-amount"
          elementAfter={<div className="FormDetailsLayout-form-field-amount-prefix">USD $</div>}
        />
      );

      return (
        <FormSection title={tForm('financialResponsibilitiesOfPatient')}>
          <RadioField
            {...register('financialResponsibilities', { required: !fieldsDisabled })}
            forceHideLabel
            options={[
              {
                value: 'deductible',
                label: tForm('deductible'),
              },
              {
                value: 'copay',
                label: tForm('coPay'),
              },
              {
                value: 'nonCovered',
                label: tForm('nonCovered'),
              },
            ]}
            disabled={loading}
            status={errors?.financialResponsibilities ? 'error' : defaultStatus}
          />
          {renderAmountField('cost')}
          {renderAmountField('depositPaid')}
          <RadioField
            {...register('depositMethod', { required: !fieldsDisabled })}
            forceHideLabel
            options={[
              {
                value: 'cash',
                label: tForm('cash'),
              },
              {
                value: 'creditCard',
                label: tForm('creditCard'),
              },
            ]}
            disabled={loading}
            status={errors?.financialResponsibilities ? 'error' : defaultStatus}
          />
          {renderAmountField('balance')}
        </FormSection>
      );
    };

    const renderPatientRecordsSection = (fieldsDisabled?: boolean) => {
      const ns = 'patientRecords';
      const defaultStatus = fieldsDisabled ? 'disabled' : 'active';
      const withNs = (name: string) => `${ns}.${name}`;
      const err = (name: string) => ((errors || {})[ns] || {})[name];
      return (
        <FormSection title={tForm('patientPleaseCompleteTheFollowing')}>
          {renderYesNo(withNs('diabetes'), 'diabetes', !fieldsDisabled, defaultStatus)}
          {renderYesNo(withNs('osteoporosis'), 'osteoporosis', !fieldsDisabled, defaultStatus)}
          {renderYesNo(withNs('flu'), 'flu', !fieldsDisabled, defaultStatus)}
          {renderYesNo(withNs('amputation'), 'amputation', !fieldsDisabled, defaultStatus)}
          {renderYesNo(withNs('hepatitisB'), 'hepatitisB', !fieldsDisabled, defaultStatus)}
          {renderYesNo(withNs('hipFracture'), 'hipFracture', !fieldsDisabled, defaultStatus)}
          {renderYesNo(withNs('tb'), 'tb', !fieldsDisabled, defaultStatus)}
          {renderYesNo(withNs('decubitusUlcer'), 'decubitusUlcer', !fieldsDisabled, defaultStatus)}
          {renderYesNo(withNs('hivAids'), 'hivAids', !fieldsDisabled, defaultStatus)}
          <InputTextField
            {...register(withNs('other'))}
            label={tForm('otherFunctionalLimitationIfAnySpecify')}
            type="text"
            maxLength={50}
            status={err('other') ? 'error' : defaultStatus}
          />
        </FormSection>
      );
    };

    const renderSignOffSection = (fieldsDisabled?: boolean) => {
      const defaultStatus = fieldsDisabled ? 'disabled' : 'active';
      const isSelf = watch('relationship') === 'self';
      return (
        <FormSection title={tForm('acceptanceByPatientCaregiver')}>
          <SignaturePadField
            {...register('signature', { required: !fieldsDisabled })}
            label={tForm('signatureOfPatientCaregiver')}
            status={errors?.signature ? 'error' : 'active'}
            value={signatureValue}
            disabled={loading}
            onChange={(value) => setValue('signature', value)}
          />
          <InputTextField
            {...register('signingName', { required: !fieldsDisabled })}
            label={tForm('pleasePrintName')}
            type="text"
            maxLength={50}
            status={errors?.signingName ? 'error' : isSelf ? 'disabled' : defaultStatus}
          />
          <RadioField
            {...register('relationship', { required: !fieldsDisabled })}
            label={tForm('relationship')}
            options={relationshipRadioOptions}
            disabled={loading}
            status={errors?.relationship ? 'error' : 'active'}
          />
          <InputTextField
            {...register('signingDate')}
            label={tForm('date')}
            placeholder={datePlaceholder}
            type="date"
            status="disabled"
          />
        </FormSection>
      );
    };

    return (
      <FormDetailsLayout
        className="AssessmentAndCarePlanForFootOrthoticsShoeOnly"
        id={form?.id}
        title={tForm('title')}
        ctaLabel={ctaLabel}
        ctaLoadingLabel={ctaLoadingLabel}
        secondaryCtaLabel={secondaryCtaLabel}
        onSubmit={handleSubmit(onSubmitWithDataMapping)}
        loading={loading}
        submitError={submitError}
      >
        {renderBasicInfoSection(!prefillEnabled)}
        {renderItemPrescribedSection(!prefillEnabled)}
        {renderAssessmentSection(!prefillEnabled)}
        {renderCarePlanSection(!prefillEnabled)}
        {renderFunctionGoalsSection(!prefillEnabled)}
        {renderPASection(!prefillEnabled)}
        {renderFinancialResponsibilitiesSection(!prefillEnabled)}
        {!!patientEnabled && renderPatientRecordsSection()}
        {!!patientEnabled && renderSignOffSection()}
      </FormDetailsLayout>
    );
  },
);

AssessmentAndCarePlanForFootOrthoticsShoeOnly.displayName = 'AssessmentAndCarePlanForFootOrthoticsShoeOnly';

export default AssessmentAndCarePlanForFootOrthoticsShoeOnly;
